/* eslint-disable camelcase */

/**
 *
 * TeamManagement
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { Button, Icon } from 'antd';
import { makeSelectAdminMembers } from './selectors';
import { makeSelectCurrentUser } from '../App/selectors';
import SubContainer from './SubContainer';
import SubHeaderContainer from './SubHeaderContainer';
import AdminMemberTable from './AdminMemberTable';
import { useHistory } from 'react-router-dom';

function MemberList(props) {
  const {
    currentUser: { inkblot_admin_yn },
  } = props;
  const history = useHistory();
  let button;

  if (inkblot_admin_yn) {
    button = (
      <Button
        htmlType="submit"
        type="primary"
        onClick={() => history.push('/administration/add_admin')}
      >
        <Icon type="plus-circle" style={{ verticalAlign: 0 }} />
        Add Admin
      </Button>
    );
  } else {
    button = <div />;
  }
  return (
    <SubContainer>
      <SubHeaderContainer>
        <h3 className="sub-header">Admins</h3>
        {button}
      </SubHeaderContainer>
      <AdminMemberTable
        currentUser={props.currentUser}
        members={props.adminMembers.data || []}
      />
    </SubContainer>
  );
}

MemberList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  adminMembers: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
  adminMembers: makeSelectAdminMembers(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MemberList);
