/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Icon, Modal } from 'antd';
import { UikWidgetTable, UikTag } from '@uik/lib';
import { destroyAdminMember } from './actions';
import { useHistory } from 'react-router-dom';

function AdminMemberTable(props) {
  const [showModal, setModal] = useState(false);
  const [userToRemove, setUserToRemove] = useState({});
  const {
    members,
    dispatch,
    currentUser: { inkblot_admin_yn },
  } = props;

  const history = useHistory();
  let modify;
  let modal;
  let remove;

  if (inkblot_admin_yn) {
    modify = (member) => (
      <Button
        htmlType="submit"
        type="default"
        disabled={!inkblot_admin_yn}
        onClick={() =>
          history.push(`/administration/${member.id}/modify_admin`)
        }
      >
        <Icon type="edit" style={{ verticalAlign: 0 }} />
        Edit
      </Button>
    );
  } else {
    modify = () => <div />;
  }

  if (inkblot_admin_yn) {
    remove = (member) => (
      <Button
        style={{ marginLeft: '20px' }}
        htmlType="submit"
        type="danger"
        disabled={!inkblot_admin_yn}
        onClick={() => {
          setUserToRemove(member);
          setModal(true);
        }}
      >
        <Icon type="minus-circle" style={{ verticalAlign: 0 }} />
        Delete
      </Button>
    );
  } else {
    remove = () => <div />;
  }
  if (inkblot_admin_yn) {
    modal = () => (
      <Modal
        title="Remove User from Admin"
        visible={showModal}
        closable={false}
        onCancel={() => {
          setModal(false);
          setUserToRemove({});
        }}
        onOk={() => {
          dispatch(destroyAdminMember(userToRemove.id));
          setModal(false);
          setUserToRemove({});
        }}
        cancelText="Return to Administration"
        okText="Remove Admin"
      >
        You are about to revoke <b>{userToRemove.name}&apos;s</b> access to
        Marchel. Are you sure you want to continue?
      </Modal>
    );
  } else {
    modal = () => <div />;
  }

  const renderMember = (member) => {
    return (
      <tr key={`key-${member.id}`}>
        <td>{member.name}</td>
        <td>{member.email}</td>
        <td>{member.roles.map((role) => renderTag(role))}</td>
        <td>{member.company_count}</td>
        <td>
          {modify(member)}
          {remove(member)}
          {modal()}
        </td>
      </tr>
    );
  };

  const renderTag = (role) => {
    let tag = { color: null, text: null };
    if (role === 'Marchel_Owner') {
      tag.text = 'OWNER';
      tag.color = 'orange';
    } else if (role === 'Ink Admin') {
      tag.text = 'INK ADMIN';
      tag.color = 'blue';
    } else if (role === 'Marchel_Senior_Admin') {
      tag.text = 'SR ADMIN';
      tag.color = 'violet';
    } else if (role === 'Marchel_Admin') {
      tag.text = 'Access';
      tag.color = 'green';
    } else if (role === 'People_Admin') {
      tag.text = 'People';
      tag.color = 'red';
    }

    if (tag.text === null) return;
    return (
      <UikTag color={tag.color} style={{ marginRight: 5 }} fill>
        {tag.text}
      </UikTag>
    );
  };

  return (
    <UikWidgetTable>
      <thead>
        <tr>
          <th>NAME</th>
          <th>EMAIL</th>
          <th>ROLES</th>
          <th>COMPANIES</th>
          <th>ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        {members && members.length ? (
          members.map((member) => renderMember(member))
        ) : (
          <tr>
            <td colSpan="4">No members</td>
          </tr>
        )}
      </tbody>
    </UikWidgetTable>
  );
}

AdminMemberTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(AdminMemberTable);
